/* @font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-Regular.eot');
  src: local('Gilroy Regular'), local('Gilroy-Regular'), url('Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-Regular.woff2') format('woff2'), url('Gilroy-Regular.woff') format('woff'),
    url('Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-weight: 600;
  font-family: 'Gilroy';
  src: url('./Gilroy-Semibold.ttf');
} */

@font-face {
  font-weight: 500;
  font-family: 'Gilroy';
  src: url('./Gilroy-Medium.ttf');
}

@font-face {
  font-weight: 700;
  font-family: 'Gilroy';
  src: url('./Gilroy-Bold.ttf');
}
