@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  &:after {
    box-sizing: border-box;
  }

  &:before {
    box-sizing: border-box;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
a,
div,
p {
  font-family: 'Gilroy' !important;
  margin: 0;
}

a {
  font-size: 14px;
  text-decoration: none;
  color: #5e6774;
}

button,
[type='reset'],
[type='submit'] {
  background-color: #ffb700;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ant-select-selector,
.ant-picker-range,
.ant-input-affix-wrapper,
.ant-picker,
input,
textarea {
  background-color: #f6f7f8 !important;
  font-weight: 500 !important;
}
.ant-upload {
  width: 100%;
}
.ant-select-selector:focus {
  border: 1px solid #ffc929 !important;
  box-shadow: 0 0 0 2px rgb(255 255 0 / 10%);
}
.ant-select-selector:active {
  border: 1px solid #ffc929 !important;
  box-shadow: 0 0 0 2px rgb(255 255 0 / 10%);
}
.ant-tabs-tab {
  font-size: 16px !important;
}
.ant-switch {
  background: rgba(0, 0, 0, 0.25);
}
.ant-drawer {
  margin-top: 63px;
}
.ant-drawer-content-wrapper {
  box-shadow: none !important;
}
.ant-drawer-close {
  display: none !important;
}
.auth__input-primary {
  height: 57px;
  background-color: #f2f2f2;
  padding: 0 22px;
}
.auth__btn-primary {
  height: 57px;
  border: none;
}
.container {
  max-width: 1130px;
  margin: 0 auto;
}
.header__items {
  cursor: pointer;
  border-bottom: 2px solid transparent;
  color: #011957;
}
.header__items:hover {
  color: #ffb700 !important;
}
.header__items-active {
  border-bottom: 2px solid #ffb700;
  transition: opacity 0.3s;
}
.submenu__items-active {
  border-right: 5px solid #ffb700;
}
.submenu__items:hover {
  background-color: #fff1cf;
}
a:hover {
  color: #011957;
}
.color-active {
  color: #011957;
}
.color-title {
  color: #000;
}
.text-bs {
  font-size: 15px;
}
.listItem:hover {
  background-color: #f9f9f9;
}
.ant-collapse-header {
  padding: 0 20px !important;
  align-items: center !important;
  border-bottom: 1px solid #eee;
}
.ant-collapse-header:hover {
  background-color: #f9f9f9;
  border-radius: 0 !important;
}
.ant-collapse-content-box {
  padding: 0 !important;
}

@media screen and (max-width: 520px) {
  .ant-collapse-header {
    padding: 0 10px !important;
  }
}
